@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;600&family=Quicksand:wght@300&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  justify-content: center;
  align-items: center;
}

body {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 100 !important;
  color: black !important;
}

.nav-bar {
  display: flex;
  height: 70px;
  background-color: black;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem;
}

.nav-bar a{
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.nav-bar p{
  font-size: 30px;
}

.nav-bag {
  display: flex;
  align-items: center;
}

.bag-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  background-color: yellow;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 600;
  color: black;
  margin-left: 5px;
}

/* Home */

.home-container {
  padding: 2rem 4rem;
}

.home-container h2 {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}

.products {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.product {
  width: 250px;
  max-width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem auto;
  padding: 1rem;
  border-radius: 15px;
  box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5), 2px 2px 5px rgba(94, 104, 121, 0.3);
}

.product img {
  width: 80%;
  margin-top: 1rem;
  margin-left:auto;
  margin-right: auto;
}

.product h3 {
  font-size: 20px;
  font-weight: 400;
}

.product .details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
}

.product .details .price {
  font-size: 20px;
  font-weight: 600;
}

.product button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  margin-top: 2rem;
  font-weight: 300;
  border: none;
  outline: none;
  cursor: pointer;
  background: black;
  color: white;
}

.product button:hover {
  color: black;
  background: whitesmoke;
  border: 1px solid black;
  transition: ease-in 0.3s;
}

/* Cart */

.cart-container {
  padding: 2rem 4rem;
}

.cart-container h2 {
  font-weight: 600;
  font-size: 30px;
  text-align: center;
}

.cart-container .titles {
  margin: 2rem 0 1rem 0;
}

.cart-container .titles h3 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.cart-item, .cart-container .titles {
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  column-gap: 0.5rem;
}

.cart-item {
  border-top: 1px solid rgba(187, 187, 187);
  padding: 1rem 0;
  font-weight: 400;
}

.cart-container .titles .product-title {
  padding-left: 0.5rem;
}

.cart-container .titles .total {
  padding-right: 0.5rem;
  justify-self: right;
}

.cart-item .cart-product {
  display: flex;
}

.cart-item .cart-product img {
  width: 100px;
  max-width: 100%;
  margin-right: 1rem;
}

.cart-item .cart-product h3 .cart-product p {
  font-weight: 400;
}

.cart-item .cart-product button {
  border: none;
  outline: none;
  margin-top: 0.7rem;
  cursor: pointer;
  background: none;
  color: grey;
}

.cart-item .cart-product button:hover {
  color: black;
}

.cart-product-quantity {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 130px;
  max-width: 100%;
  border: 0.5px solid rgb(177, 177, 177);
  border-radius: 5px;
}

.cart-product-quantity button {
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  color: grey;
  padding: 0.7rem 1.5rem;
}

.cart-product-quantity .count {
  padding: 0.5rem 0;
}

.cart-product-total-price {
  justify-self: right;
  padding-right: 0.5rem;
  font-weight: 500;
}

/* cart summary */
.cart-summary {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid rgb(187, 187, 187);
  padding-top: 2rem;
}

.cart-summary .clear-cart {
  width: 130px;
  max-width: 100%;
  height: 40px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  border: 0.5px solid rgb(177, 177, 177);
  color: grey;
  background: none;
  outline: none;
  cursor: pointer;
}

.cart-checkout {
  width: 270px;
  max-width: 100%;
}

.cart-checkout .subtotal {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 400;
}

.cart-checkout .subtotal .amount {
  font-weight: 600;
}

.cart-checkout p {
  font-size: 14px;
  font-weight: 400;
  margin: 0.5rem 0;
}

.cart-checkout button {
  width: 270px;
  height: 40px;
  border: none;
  outline: none;
  cursor: pointer;
  background: black;
  color: white;
  padding: 0.7rem 1.5rem;
  border: 1px solid rgb(177, 177, 177);
  border-radius: 5px;
  font-weight: 400;
}

.cart-checkout button:hover {
  color: black;
  background: white;
  transition: ease-in 0.2s;
}

.continue-shopping,
.start-shopping {
  margin-top: 1rem;
}

.continue-shopping a,
.start-shopping a {
  color: grey;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.continue-shopping a span,
.start-shopping a span {
  margin-left: 0.5rem;
}

.cart-empty {
  font-size: 20px;
  font-weight: 400;
  margin-top: 2rem;
  color: rgb(84, 84, 84);
  display: flex;
  flex-direction: column;
  align-items: center;
}